<template>
  <div>
    <v-card v-for="brew in breweries" :key="brew.id" color="black">
      <div v-if="brew.objectId === $route.params.id">
        <div class="image-container">
          <img
            :src="require('@/assets/images/pubs/' + brew.logoURL)"
            width="80%"
            height="180%"
          />
          <div v-if="brew.checked === true" class="shamrock">
            <img src="@/assets/images/shamrock.png" />
          </div>
          <v-alert v-if="brew.checked === true" color="#12b25e" type="success"
            >You've checked into this location!</v-alert
          >
        </div>
        <v-card color="white"
          ><h1>{{ brew.name }}</h1>
          <p>
            {{ brew.description }}
          </p>
          <p class="center">
            {{ brew.address }}
          </p>
          <p class="center">
            <a :href="telephone">{{ brew.phone }}</a> |
            <a :href="brew.siteURL">Website</a>
          </p>
          <a :href="mapLink" style="text-decoration: none" target="_blank"
            ><v-btn x-small class="py-4 mb-4" color="#12b25e"
              >Directions</v-btn
            ></a
          >
        </v-card>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Brew",
  data() {
    return {};
  },
  created() {},
  watch: {
    $route(to, from) {
      console.log(to);
      console.log(from);
      // react to route changes...
      this.loadData();
    },
  },
  computed: {
    ...mapGetters("brew", ["breweries"]),
    telephone() {
      var tel;
      for (var i = 0; i < this.breweries.length; i++) {
        if (this.breweries[i].objectId === this.$route.params.id) {
          tel = this.breweries[i].tel;
        }
      }
      return "tel:+" + tel;
    },
    mapLink() {
      var addr;
      var placeId;
      for (var i = 0; i < this.breweries.length; i++) {
        if (this.breweries[i].objectId === this.$route.params.id) {
          addr = this.breweries[i].address;
          placeId = this.breweries[i].placeId;
        }
      }
      addr = encodeURIComponent(addr);
      return (
        "https://www.google.com/maps/dir/?api=1&destination=" +
        addr +
        "&destination_place_id=" +
        placeId
      );
    },
  },
};
</script>

<style>
.shamrock {
  position: absolute;
  top: 30px;
  left: 30px;
}
.shamrock img {
  height: 50px;
  width: 50px;
}
p {
  color: black;
  font-size: 0.8rem;
  white-space: pre-wrap;
  text-align: left;
  padding: 10px;
}
p.center {
  text-align: center;
}
</style>