<template>
  <div>
    <h1>Directions</h1>

    <v-img
      :lazy-src="require('@/assets/images/map.png')"
      :src="require('@/assets/images/map.png')"
    ></v-img>
    <v-list v-for="brew in breweries" :key="brew.id" color="black">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">{{ brew.name }}</v-list-item-title
          ><a :href="mapLink" style="text-decoration: none" target="_blank"
            ><v-btn x-small class="py-4 mb-4" color="#12b25e"
              >Map Directions</v-btn
            ></a
          ></v-list-item-content
        >
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Brew",
  data() {
    return {};
  },
  created() {},
  watch: {
    $route(to, from) {
      console.log(to);
      console.log(from);
      // react to route changes...
      this.loadData();
    },
  },
  computed: {
    ...mapGetters("brew", ["breweries"]),
    telephone() {
      var tel;
      for (var i = 0; i < this.breweries.length; i++) {
        if (this.breweries[i].objectId === this.$route.params.id) {
          tel = this.breweries[i].tel;
        }
      }
      return "tel:+" + tel;
    },
    mapLink() {
      var addr;
      var placeId;
      for (var i = 0; i < this.breweries.length; i++) {
        if (this.breweries[i].objectId === this.$route.params.id) {
          addr = this.breweries[i].address;
          placeId = this.breweries[i].placeId;
        }
      }
      addr = encodeURIComponent(addr);
      return (
        "https://www.google.com/maps/dir/?api=1&destination=" +
        addr +
        "&destination_place_id=" +
        placeId
      );
    },
  },
};
</script>

<style scoped>
p {
  color: #fff;
}
.title {
  margin-bottom: 0.6rem;
}
</style>